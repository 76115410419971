<template>
  <div style="background-color: #f7f7f7">
    <div>
      <div class="hospitalBox">
        <img src="../../assets/touxiang.png"
          style="width: 50px; margin: 1%" />
        <div class="title"
          style="font-family: PingFang SC; font-weight: 500;margin: 1%">
          <div>姓名：{{name}}</div>
          <div>年龄：{{age}}岁</div>
        </div>
      </div>
      <div style="padding: 20px 0 0 20px">
        <div class="biankuang"></div>
        <h3 style="
            display: initial;
            padding-left: 10px;
            font-family: PingFang SC;
            font-weight: 500;
          ">
          身体指标
        </h3>
      </div>
      <div class="zhibiao">
        <router-link to="/pension/xxList/1"
          style="color:black;">
          <img src="../../assets/xueya.png"
            style="width: 40px; margin: 10px" />
          <div class="zhibiaoXy">
            <div>血压</div>
            <!-- <div class="time">30天内平均值</div> -->
          </div>
          <div class="zhibiaoNr">
            <div>
              <div style="width: 45%; float: left">
                {{jkList.HighPressure}}&nbsp;&nbsp;mmHg<br />高压
              </div>
              <div style="width: 45%; float: left">
                {{jkList.LowPressure}}&nbsp;&nbsp;mmHg<br />低压
              </div>
            </div>
          </div>
        </router-link>

      </div>
      <div class="zhibiao">
        <router-link to="/pension/xxList/3"
          style="color:black;">
          <img src="../../assets/xinlv.png"
            style="width: 40px; margin: 10px" />
          <div class="zhibiaoXy">
            <div>心率</div>
            <div class="time"
              v-if="jkList.HeartRate == '0'">暂无</div>
            <div v-else
              class="time">{{jkList.HeartRate}}&nbsp;&nbsp;(bpm)</div>
          </div>
        </router-link>
        <!-- <div class="zhibiaoNr">
          <div>
            <div style="width: 95%; float: left">
              <div style="display: contents; color: black">{{jkList.HeartRate}}</div>
              &nbsp;&nbsp;(bpm)
            </div>
          </div>
        </div> -->
      </div>

      <div class="zhibiao">
        <router-link to="/pension/xxList/2"
          style="color:black;">
          <img src="../../assets/tiwen.png"
            style="width: 40px; margin: 10px" />
          <div class="zhibiaoXy">
            <div>体温</div>
            <div class="time"
              v-if="jkList.Temperature  == '0'">暂无</div>
            <div v-else
              class="time">{{jkList.Temperature }}&nbsp;&nbsp;(℃)</div>
          </div>
        </router-link>
      </div>

      <div class="zhibiao">
        <router-link to="/pension/xxList/4"
          style="color:black;">
          <img src="../../assets/xuetang.png"
            style="width: 40px; margin: 10px" />
          <div class="zhibiaoXy">
            <div>血糖</div>
            <div class="time"
              v-if="jkList.BloodSugar == '0'">暂无</div>
            <div v-else
              class="time">{{jkList.BloodSugar}}&nbsp;&nbsp;(mmol/l)</div>
          </div>
        </router-link>
      </div>

      <div class="zhibiao">
        <router-link to="/pension/xxList/5"
          style="color:black;">
          <img src="../../assets/tizhong.png"
            style="width: 40px; margin: 10px" />
          <div class="zhibiaoXy">
            <div>体重</div>
            <div v-if="jkList.Weight == '0'"
              class="time">暂无</div>
            <div v-else
              class="time">{{jkList.Weight}}&nbsp;&nbsp;(KG)</div>
          </div>
        </router-link>
      </div>

      <div class="zhibiao">
        <router-link to="/pension/xxList/6"
          style="color:black;">
          <img src="../../assets/xuezhi.png"
            style="width: 40px; margin: 10px" />
          <div class="zhibiaoXy">
            <div>血脂</div>
            <div v-if="jkList.Bloodfat == '0'"
              class="time">暂无</div>
            <div v-else
              class="time">{{jkList.Bloodfat}}&nbsp;&nbsp;(mmol/L)</div>
          </div>
        </router-link>
      </div>

      <div class="zhibiao">
        <router-link to="/pension/xxList/7"
          style="color:black;">
          <img src="../../assets/danbai.png"
            style="width: 40px; margin: 10px" />
          <div class="zhibiaoXy">
            <div>糖化血红蛋白</div>
            <div v-if="jkList.AGES == '0'"
              class="time">暂无</div>
            <div v-else
              class="time">{{jkList.AGES}}&nbsp;&nbsp;(mmol/L)</div>
          </div>
        </router-link>
      </div>

      <div class="zhibiao">
        <router-link to="/pension/xxList/8"
          style="color:black;">
          <img src="../../assets/xueyang.png"
            style="width: 40px; margin: 10px" />
          <div class="zhibiaoXy">
            <div>血氧</div>
            <div v-if="jkList.BloodOxygen == '0'"
              class="time">暂无</div>
            <div v-else
              class="time">{{jkList.BloodOxygen}}&nbsp;&nbsp;(bpm)</div>
          </div>
        </router-link>
      </div>

      <div class="zhibiao">
        <router-link to="/pension/xxList/9"
          style="color:black;">
          <img src="../../assets/niaosuan.png"
            style="width: 40px; margin: 10px" />
          <div class="zhibiaoXy">
            <div>尿酸</div>
            <div v-if="jkList.Urinalysis == '0'"
              class="time">暂无</div>
            <div v-else
              class="time">{{jkList.Urinalysis}}&nbsp;&nbsp;(μmol/L)</div>
          </div>
        </router-link>
      </div>

      <!-- <div class="zhibiao">
        <img src="../../assets/jg.png"
          style="width: 50px; margin: 10px" />
        <div class="zhibiaoXy">
          <div>睡眠</div>
          <div class="time">30天内平均值</div>
        </div>
        <div class="zhibiaoNr">
          <div>
            <div style="width: 95%; float: left">
              <div style="display: contents">--</div>
              &nbsp;&nbsp;小时<br />睡眠时间
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import {
  WeGetElderlyBaseInfo,
  WxHealthyAssessment,
} from "@/api/Pension";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Toast } from "vant";
export default {
  data () {
    return {
      hId: "",
      name: "",
      iden: "",
      age: "",
      jkList: {},
      OpenID: '',
    }
  },
  created () {
    this.laorenXx();
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    console.log(getOpenId())
  },
  methods: {
    laorenXx: function () {
      this.OpenID = getOpenId();
      WeGetElderlyBaseInfo({ openID: this.OpenID }).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.hId = res.data.data.HId;
          this.name = res.data.data.Name;
          this.iden = res.data.data.IdNumber;
          this.jiankangPingGu(res.data.data.HId)
          var birth = null;
          var myDate = new Date();
          var month = myDate.getMonth() + 1;
          var day = myDate.getDate();
          var age = 0;
          if (this.iden.length === 18) {
            age = myDate.getFullYear() - this.iden.substring(6, 10) - 1;
            birth =
              this.iden.substring(6, 10) +
              "-" +
              this.iden.substring(10, 12) +
              "-" +
              this.iden.substring(12, 14);
            if (
              this.iden.substring(10, 12) < month ||
              (this.iden.substring(10, 12) == month && this.iden.substring(12, 14) <= day)
            )
              age++;
          }
          if (this.iden.length === 15) {
            age = myDate.getFullYear() - this.iden.substring(6, 8) - 1901;
            birth =
              "19" +
              this.iden.substring(6, 8) +
              "-" +
              this.iden.substring(8, 10) +
              "-" +
              this.iden.substring(10, 12);
            if (
              this.iden.substring(8, 10) < month ||
              (this.iden.substring(8, 10) == month && this.iden.substring(10, 12) <= day)
            )
              age++;
          }
          this.age = age;
          console.log(this.age);
        } else {
          Toast.fail(res.data.msg);
        }

      })
    },
    jiankangPingGu: function (hId) {
      WxHealthyAssessment({ hId: hId }).then((res) => {
        console.log(res);
        console.log(res.data);
        this.jkList = res.data
      })
    },
  }
}

</script>
<style scoped>
.hospitalBox {
  position: relative;
  background: #fff;
  margin-top: 10px;
}
.title {
  height: 50px;
  line-height: 50px;
  display: inline-block;
  margin: 10px;
}
.title div {
  float: left;
  margin: 0 15px;
  font-size: 1.17em;
  font-weight: 540;
}
.zhibiao {
  position: relative;
  width: 90%;
  margin: 15px 5%;
  background: #fff;
  border-radius: 15px;
}
.zhibiaoXy {
  height: 40px;
  line-height: 40px;
  display: inline-block;
  margin: 10px;
  width: 65%;
}
.zhibiaoXy div {
  float: left;
  font-size: 16px;
  font-weight: 540;
}
.zhibiaoNr {
  position: inherit;
  line-height: 30px;
  display: inline-block;
  margin-left: 80px;
  width: 80%;
  color: #999999;
}
.time {
  right: 10px;
  position: absolute;
  font-size: 14px;
  color: #999999;
}
.biankuang {
  border: 2px solid #ff9d30ff;
  background-color: #ff9d30ff;
  width: 0;
  height: 20px;
  float: left;
  border-radius: 2px;
}
</style>