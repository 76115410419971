
import request from '@/utils/request'
// 微信获取老人录入健康信息列表
export function WePageHealthyList (query) {
  return request({
    url: 'Pension/WePageHealthyList',
    method: 'get',
    params: query
  })
}
// 微信录入老人健康信息
export function WeSaveHealthy (data) {
  return request({
    url: 'Pension/WeSaveHealthy',
    method: 'post',
    data: data
  })
}
// 微信删除老人健康信息
export function WeDeleteHealthy (data) {
  return request({
    url: 'Pension/WeDeleteHealthy',
    method: 'post',
    data: data
  })
}
// 微信获取老人体检记录
export function WePagePhysicalsList (query) {
  return request({
    url: 'Pension/WePagePhysicalsList',
    method: 'get',
    params: query
  })
}
// 微信录入老人体检记录
export function WeSavePhysicals (data) {
  return request({
    url: 'Pension/WeSavePhysicals',
    method: 'post',
    data: data
  })
}
// 微信删除老人体检记录
export function WeDeletePhysicals (data) {
  return request({
    url: 'Pension/WeDeletePhysicals',
    method: 'post',
    data: data
  })
}
// 小程序获取养老社区列表
export function WeGetCommunityList (query) {
  return request({
    url: 'Pension/WeGetCommunityList',
    method: 'get',
    params: query
  })
}
// 微信获取老人个人信息
export function WeGetElderlyBaseInfo (query) {
  return request({
    url: 'Pension/WeGetElderlyBaseInfo',
    method: 'get',
    params: query
  })
}
// 小程序获取健康评估
export function WxHealthyAssessment (query) {
  return request({
    url: 'Pension/WxHealthyAssessment',
    method: 'get',
    params: query
  })
}
// 微信获取养老资讯列表
export function WeGetArticleList (query) {
  return request({
    url: 'PensionInteract/WeGetArticleList',
    method: 'get',
    params: query
  })
}
// 微信获取养老资讯详情
export function WeGetArticleInfo (query) {
  return request({
    url: 'PensionInteract/WeGetArticleInfo',
    method: 'get',
    params: query
  })
}
// 小程序修改绑定老人基本信息
export function WeBindSaveElderly (data) {
  return request({
    url: 'Pension/WeBindSaveElderly',
    method: 'post',
    data: data
  })
}